import {
	FButton,
	FRWDNavBar,
	FText,
} from "@fantaskticedtechlimited/fui-complib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MenuButtonList } from "./menuButtonList";
import { SideBarProps } from "./types";
import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import * as styles from "./styles";
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import { ImageAsset } from "../../assets/images";
import { FIcon, FIconTypes } from "@fantaskticedtechlimited/fui-iconlib";
import CourseMemberSelectDropDown from "../../Components/CourseMemberSelectDropDown";
import { useAuthContext } from "../../contexts/authContext";

function RWDNavBar(props: SideBarProps) {
	const { signOut } = useAuthContext()
	const navigate = useNavigate()
	return (
		<Fragment>
			<FRWDNavBar
				rwdMode={props.rwdMode}
				overlayMenuPosition="right"
				visible={true}
				logo={
					<Link
						to="/"
						style={{
							textDecoration: "none",
							display: "flex",
							flexDirection: props.rwdMode === RWDMode.FULL ? "column" : "row",
							justifyContent: "center",
							alignItems: "center",
							rowGap: props.rwdMode === RWDMode.FULL ? "8px" : undefined,
							columnGap: props.rwdMode !== RWDMode.FULL ? "8px" : undefined,
						}}
					>
						<img
							src={ImageAsset.logo}
							style={{
								width: "75px",
							}}
							alt="logo"
						/>
						<FText
							font={FFontTypes.Small_Title({
								fontWeight: 300,
								fontFamily: "Montserrat",
							})}
							color={FColorTypes.PRIMARY_BLACK}
							style={{}}
						>
							Student Portal
						</FText>
					</Link>
				}
				topBarStyle={{
					height: "3.5rem",
					backgroundColor: FColorTypes.PRIMARY_WHITE,
				}}
				containerClassName={styles.sideNavbar}
				menuIconProps={{
					strokeColor: FColorTypes.UNIPLUS_ORANGE,
					containerStyle: {
						backgroundColor: "transparent",
						padding: 0,
					},
				}}
				closeIconProps={{
					strokeColor: FColorTypes.UNIPLUS_ORANGE,
					containerStyle: {
						backgroundColor: "transparent",
						padding: 0,
						alignSelf: "flex-end",
					},
				}}
			>
				<CourseMemberSelectDropDown />
				<MenuButtonList routes={props.mainMenuRoutes} autoExpand />
				<MenuButtonList routes={props.footerMenuRoutes} isFooter />
				<FButton
					onClick={() =>{
						signOut()
						navigate("/login",{replace:true})
					}}
					label="Logout"
					labelStyle={{
						color: FColorTypes.PRIMARY_GREY,
					}}
					style={{
						alignSelf: "flex-start",
					}}
					leadingComponents={<FIcon name={FIconTypes.LOGOUT} />}
				/>
			</FRWDNavBar>
		</Fragment>
	);
}

export default RWDNavBar;
